.pesan{
    white-space: pre-line;
    overflow: hidden;
    height: 120px;
}
.card{
    padding: 0 50px 0 50px;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: inset;
    box-shadow: 0 4px 8px 0 rgba(0.05, 0.05, 0.05, 0.05);
}
.line{
    height: 1.5px;
    width: 100%;
    background-color: black;
    margin-top: 2px;
    margin-bottom: 2px;
}
.title{
    font-weight: bolder;
}
#date{
    font-size: smaller;
}
#edit{
    color: black;
    text-decoration: none;
    align-items: center;
    text-align: center;
    height: 40px;
}
#edit:hover{
    background-color: #d26903;
    color: white;
    
}
#hapus{
    align-items: center;
    text-align: center;
    height: 40px;
}
#hapus:hover{
    background-color: #de2905;
    color: white;
}
.detail{
    color:#58929C;
    text-decoration: none;
}
