.judulPengumuman{
  color: #d26903;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 39px;
  letter-spacing: 0em;

}
.section {
  height: 700px;
  width: 100%;  
}

.section_pengumuman {
  height: 900px;
  width: 100%;
}

.pengumuman{
  /* margin-left: 50px; */
  /* width: 75%; */
  margin-left: 48px;
  margin-right: 24px
}
.home{
  margin-top: 150px;
  margin-left: 24px;
  margin-right: 24px;
}
.jargon{
  font-size: 50px;
  font-weight: 800;
  color: #d26903;
}
.logo_text{
  width: 1000px;
  height: 50px;
}
.space{
  margin-right: 0px;
  padding: 0px;
}
.link{
  text-decoration: none;
  color: #58929C;
}
.link:hover{
  color: #d26903;
}
.search_bar {
  border-radius: 16px;
  border-style: hidden;
  box-shadow: inset;
  box-shadow: 4px 4px 8px 4px rgba(0.05, 0.05, 0.05, 0.05);
}
@media only screen and (max-width: 600px) {
  .jargon {
    font-size: 40px;
    font-weight: 800;
    color: #d26903;
  }
}