.Sidebar {
    min-height: 100vh;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0.05, 0.05, 0.05, 0.05);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    position: fixed;
    }

.SidebarList {
    height: auto;
    padding: 0;
    width: 100%;
}

.SidebarList .row {
    width: 100%;
    height: 60px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.SidebarList .row:hover {
    cursor: pointer;
    background-color: #D269301f;
    color: #D26930;
}

.SidebarList #active {
    background-color: #D269301f;
    color: #D26930;
}

.SidebarList #box {
    color: black;
    width: 250px;
    height: 72px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
}

.row #icon {
    flex: 30%;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 70%;
}