body {
  margin: 0;
  padding-top: 88px;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  padding-top: 64px;
  padding-bottom: 120px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.modal {
  height: 600px;
  margin-top: 150px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
}
.modalHeader {
  height: 100px;
}
.modalBody {
  height: fit-content;
}
.modalFooter {
  margin-right: 27px;
}
.modalCiteHeader {
  height: 50px;
}
.close {
  border: 0;
  padding-left: 0px;
  padding-top: 0px;
  border-radius: 20px;
  margin-bottom: 0px;
  width: 32px;
  height: 32px;
  background-color: #d3d3d3;
  text-align: center;
  display: inline-block;
}
.cancel {
  background-color: #f8f8f8;
  border-color: #d26903;
  color: #d26903;
  width: 180px;
  margin-right: 16px;
}
.close:hover {
  background-color: rgba(210, 105, 48, 0.63);
}
.accept {
  background-color: #d26903;
  border-color: #d26903;
  color: #ffffff;
  width: 180px;
}

.check-container {
  background: #41835d;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  left: 0px;
  top: 0px;
}

.searchbaru {
  display: flex;
  justify-content: space-between;
  background-color: aqua;
}

/* Button */
.primary-btn {
  padding: 16px 56px 16px 56px;
  background: #d26930;
  box-shadow: 0px 2px 5px 0px rgba(210, 105, 48, 1);
  border: 0;
  border-radius: 8px;
}

.btn-full-width {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

.btn-orange {
  background-color: #d26930;
  border-color: #d26930;
  border-radius: 8px;
  color: #fff;
}

.btn-no-outline {
  background-color: #FFFFFF;
  color: #d26930;
  border: 0;
}

.btn-no-outline:hover {
  background-color: #d26930;
  color: #fff;
}

.btn-orange-secondary {
  background-color: #D269301f;
  color: #d26930;
}

.btn-orange-secondary:hover {
  background-color: #d26930;
  color: #fff;
}

.btn-success-outline {
  border-color: rgba(65, 131, 93, 1);
  border-width: 4px;
  border-radius: 8px;
  background-color: #fff;
  color: rgba(65, 131, 93, 1);
}

/* Text */
.text-charcoal {
  color: #333333;
}

.text-black {
  color: #ffffff;
}

.text-disabled {
  color: #808080;
}

.text-institutional-white {
  color: #f8f8f8;
}

.text-orange {
  color: #d26930;
}

.text-white {
  color: #ffffff;
}

.text-error {
  color: #b9281f;
}

.text-main-header {
  font-family: Mulish;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 67px;
  letter-spacing: 0em;
}

.text-section-header {
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 39px;
  letter-spacing: 0em;
}

.text-subheader {
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 34px;
  letter-spacing: 0em;
}

.text-bold-title {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

.text-regular-title {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}

.text-black-large {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0em;
}

.text-bold-large {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.text-large {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.text-black {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0em;
}

.text-bold {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.text-normal {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.text-black-small {
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0em;
}

.text-bold-small {
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}

.text-small {
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}

.link {
  color: black;
}

.link:hover {
  color: #d26903;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper {
  width: 100%;
}

.content-box {
  margin-top: 50px;
}

#pagination {
  margin-top: 50px;
  margin-bottom: 30px;
}