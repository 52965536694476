.cardkaril {
    border-radius: 16px;
}

.unduh {
    color: #d26930;
  }

#pagination{
    margin-top: 50px;
    margin-bottom: 30px;
}

.mainContent {
    background: rgba(252, 252, 252, 1) !important;
}

.link {
    color: black;
  }
  
.link:hover {
color: #d26903;
}

#features {
  /* margin-right: 18px; */
  color: #d26903;
  font-weight: 620;
  padding: auto;
  border: 0;
  background-color: #ffffff;
  /*background-color: #F8F8F8;*/
  /* width: 140px; */
  align-self: flex-start;
  margin-top: 20px;
  text-decoration: none;
}

#features:hover{
  text-decoration: underline;
}